import { useEffect, useState } from 'react';

import Text from 'components/atoms/text';
import Button from 'components/molecules/button';

import styles from './styles.module.scss';

const getEtag = () =>
  fetch(origin, { method: 'HEAD' }).then(
    (resp) => resp.headers.get('etag'),
    () => null
  );

async function doChecks(abortSignal: AbortSignal, onNewVersion: () => void) {
  const initialEtag = await getEtag();
  if (!initialEtag) return; // failed to request initial etag, so there'll be nothing to check against

  const check = setInterval(
    async () => {
      const newEtag = await getEtag();
      if (!newEtag) return; // failed to request new etag, this _should_ be a transient error so we stop here and retry later

      if (newEtag === initialEtag) return; // we're still on the same version
      if (abortSignal.aborted) return; // our parent effect has cancelled us, so a state update is unsafe
      onNewVersion();
    },
    5 * 60 * 1000
  );

  abortSignal.addEventListener('abort', () => clearInterval(check));
}

const SHOW_OUTDATED = localStorage.getItem('FORCE_OUTDATED_BANNER') !== null;

export default function VersionChecker() {
  const [outdated, setOutdated] = useState(SHOW_OUTDATED);

  useEffect(() => {
    const controller = new AbortController();

    doChecks(controller.signal, () => setOutdated(true));

    return () => {
      controller.abort();
    };
  }, []);

  if (!outdated) return null;

  return (
    <div className={styles.banner}>
      <Text
        with18Font
        content="WorkOptima has been updated! Please refresh the page to apply updates."
      />
      <Button
        label="View changelog"
        view="Default"
        isOutlined
        isSmall
        onClick={() => {
          FreshworksWidget('open', 'article', { id: 47000436764 });
        }}
      />
    </div>
  );
}
